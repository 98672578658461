<template>
  <div>
    <Breadcrumbs />
    <section class="subscribe-newsletter pb-5">
      <b-container>
        <b-row class="login-wrap justify-content-center">
          <b-col lg="6" xl="6">
            <div class="title">
              <h4>{{ $t("subscribe_newsletter_title") }}</h4>
            </div>
            <div class="desc">{{ $t("subscribe_newsletter_description") }}</div>
            <div class="newsletter-form py-4">
              <b-form-group label="E-mail" label-for="email">
                <b-form-input
                  id="email"
                  v-model="form.email"
                  placeholder="Naar welk E-mailadres mogen wij onze nieuwsbrief sturen?"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Aanhef" label-for="aanhef">
                <b-form-input
                  id="aanhef"
                  v-model="form.aanhef"
                  placeholder="Hier komt tekst"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Voornaam" label-for="voornaam">
                <b-form-input
                  id="voornaam"
                  v-model="form.Voornaam"
                  placeholder="Wat is je voornaam ?"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Achternaam" label-for="achternaam">
                <b-form-input
                  id="achternaam"
                  v-model="form.achternaam"
                  placeholder="Wat is je achternaam?"
                ></b-form-input>
              </b-form-group>
              <div class="typeof-news-letter mb-4">
                <strong class="d-block mb-3"
                  >Ik wil E-mails ontvangen met:</strong
                >
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="status"
                  name="checkbox-1"
                  value="accepted"
                >
                  Nieuwsbrieven, acties en aanbiedingen
                </b-form-checkbox>
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="status"
                  name="checkbox-2"
                  value="accepted"
                >
                  Uitnodigingen voor VIP sessies, workshops en demonstraties
                </b-form-checkbox>
              </div>
              <b-button
                type="button"
                variant="info"
                class="btn btn-primary w-100"
                >Maak mijn account aan</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "../../components/common/Breadcrumbs.vue";
export default {
  data() {
    return {
      form: {
        email: "",
        aanhef: "",
      },
    };
  },
  components: {
    Breadcrumbs,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
};
</script>
<style lang="scss">
@import "@/base/scss/variables.scss";
.subscribe-newsletter {
  .title {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid $borderLightColor;

    h4 {
      margin-bottom: 0;
    }
  }
}
</style>
